

.breadcrumb {
    font-size: 1rem;
    color: #555;
    font-weight: 500;

}

.breadcrumb span {
    color: #0073e6;
    cursor: pointer;
}

.file-manager-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.file-manager  {
    min-height: 80Vh ;

}

.file-manager h1 {
    font-size: 1.8rem;
    color: #333;
}

.filters {
    display: flex;
    align-items: center;
}

.search-bar {
    padding: 8px 15px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 25px;
    margin-right: 20px;
    outline: none;
    transition: all 0.3s ease;
}



.search-bar::placeholder {
    color: #888;
}

.file-table {
    width: 100%;
    border-collapse: collapse;


}

.file-table th,
.file-table td {
    padding: 12px 15px;
    text-align: left;

}
.file-table th {
    background-color: #f2f2f2;
}

.file-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.file-table tr:hover {
    background-color: #e6f7ff;
    cursor: pointer;
}

.action-btn {
    padding: 5px 10px;
    font-size: 0.8rem;
    color: #fff;
    background-color: #3498db;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.action-btn:hover {
    background-color: #2980b9;
}

button:focus {
    outline: none;
}
.search-container {
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
    padding: 8px;
    border-radius: 20px;
    width: 100%;
    max-width: 500px;
    margin: 10px 0;
}

.search-container input {
    width: 100%;
    padding: 8px;
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 16px;
    border-radius: 20px;
    margin-left: 8px;
}

.search-container input::placeholder {
    color: #888;
}

.search-container .search-icon {
    font-size: 18px;
    color: #888;
}

.search-bar {
    margin-left: 8px;
    width: 100%;
}
.file-name {
    white-space: nowrap; /* Ngăn văn bản xuống dòng */
    overflow: hidden; /* Ẩn phần văn bản tràn ra ngoài */
    text-overflow: ellipsis; /* Thêm dấu ... khi văn bản bị cắt */
    max-width: 400px; /* Đặt chiều rộng tối đa cho tên file */
    display: inline-block; /* Đảm bảo thuộc tính hoạt động đúng */
    margin-left :  10px
}
.file-name-over {
    display: flex;
    text-align: center;
}
.td-foot {
    border-top: 2px solid #0073e6;
}
.td-foot .MuiTablePagination-selectLabel {
    margin : 0;
}.td-foot .MuiTablePagination-displayedRows {
    margin : 0;
}
 .dropDownFile{
     margin-bottom : 20px;
 }