body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
    --primary-color: rgb(60, 141, 188);
}

::-webkit-scrollbar-thumb {
    background: #3c8dbc !important;
    border-radius: 0 !important;
    width: 12px !important;
}

::-webkit-scrollbar-track {
    background: #f1f1f1 !important;
}

::-webkit-scrollbar-track {
    background: rgb(179, 177, 177);
    border-radius: 0 !important;
}

::-webkit-scrollbar {
    width: 10px !important;
    height: 10px !important;
}

.card {
    padding: 1rem !important;
    border-radius: 0 !important;
    box-shadow:
        rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
        rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.card .card {
    box-shadow: none;
}

.p-dialog-content .card {
    box-shadow: none !important;
}

.p-button-sm {
    min-height: 40px !important;
    min-width: 100px !important;
    font-weight: 600;
}

.p-tree {
    padding: 1rem 0.5rem 1rem 0;
}
.p-tree .p-treenode-children {
    padding: 0 0 0 0.2rem;
}

.p-datatable .p-datatable-tbody > tr > td {
    width: auto;
    font-size: small;
    padding: 6px 7px 6px 7px;
    text-align: left;
}
.p-datatable .p-datatable-thead > tr > th {
    font-size: small;
    padding: 12px 8px 12px 8px;
}

.p-datatable td {
    word-wrap: break-word;
}

.p-tabview .p-tabview-panels {
    padding: 1rem 0;
}

.bg-color {
    background-color: #f8f9fa !important;
}

.p-dialog-title {
    font-size: 1.25rem !important;
    font-weight: bold !important;
}

.p-splitbutton {
    min-height: 40px !important;
    min-width: 200px;
}

.p-button-label {
    font-weight: 600 !important;
}
.p-column-title {
    width: 100%;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    color: #0288d1 !important;
    border-color: #0288d1 !important;
}

.p-tabview-title {
    font-weight: 600 !important;
}

.p-dialog-header {
    background-color: #f8f9fa !important;
}

.p-button {
    background-color: #0288d1;
    border-color: #0288d1;
}

.p-inputtext {
    min-height: 50px !important;
}

.p-button.p-button-warning,
.p-tag.p-tag-warning {
    color: #ffffff;
}

.p-button.p-button-warning:enabled:hover {
    color: #ffffff;
}

.card .card .header__list-form .text-xl {
    font-size: 1.1rem !important;
}

.tox-tinymce {
    border-radius: 0px !important;
    border: 1px solid #ced4da !important;
}

.change-disabled .p-disabled,
.change-disabled .p-component:disabled {
    background-color: #f2f2f2;
    opacity: 1 !important;
    cursor: crosshair;
}

.p-component .p-dropdown-filter-container .p-dropdown-filter {
    max-height: 40px !important;
}

.deleted-row {
    text-decoration: line-through;
    background-color: #ebcccc !important;
}

.total-consumption {
    background-color: #e2e6ee !important;
    font-weight: bold;
}

.adjustmentVoucher-row {
    background-color: #b5b3b3 !important;
}

.p-datatable .p-datatable-thead > tr > th {
    text-align: center;
}

/*.p-datatable .p-datatable-tbody > tr > td {*/

/*    padding: 0.5rem  0.25rem !important;*/

/*}*/
/*.p-datatable .p-datatable-thead > tr > th {*/
/*    padding: 0.5rem  0.25rem !important;*/
/*}*/
.invoice_id-row {
    background-color: #e1f2ff !important;
}
.show {
    word-wrap: break-word;
    max-width: 60ch;
}

.z1 {
    background-color: black;
    color: white;
}

.z3 {
    border-color: white !important;
}

.z2 {
    background-color: #bbc7ef;
    color: #374687;
}

.pttt {
    page-break-before: always;
}
@media screen and (max-width: 900px) {
    .column {
        width: 50%;
    }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 200px) {
    .column {
        width: 100%;
    }
}
@media print {
    .z1 {
        background-color: white;
        color: black;
    }
    .z2 {
        background-color: white;
        color: black;
    }
    .z3 {
        border-color: black !important;
    }
    .pttt {
        page-break-before: always;
    }
}
.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody)
    .p-datatable-tbody
    > tr
    > td:has(.w-full.text-white.h-full.maintenance) {
    background-color: rgb(29, 132, 199);
    text-align: center;
}

.controls {
    margin-bottom: 20px;
}

.calendar {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px;
}

.day {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: center;
}

.task-day {
    background-color: #ffeb3b;
}

.day:nth-child(1) {
    grid-column: 2;
}

.day:nth-child(2) {
    grid-column: 3;
}

.day:nth-child(3) {
    grid-column: 4;
}

.day:nth-child(4) {
    grid-column: 5;
}

.day:nth-child(5) {
    grid-column: 6;
}

.day:nth-child(6) {
    grid-column: 7;
}

.day:nth-child(7) {
    grid-column: 1;
}

#aliosa:hover {
    box-shadow: 7px 5px 56px -14px #ff2f2f;
}

#aliosa:active {
    transform: scale(0.97);
    box-shadow: 7px 5px 56px -10px #ff2f2f;
}
.ui-input-container {
    position: relative;
    width: 75%;
}

.ui-input {
    width: 100%;
    padding: 10px 10px 10px 10px;
    font-size: 1em;
    border: none;
    border-bottom: 2px solid #ccc;
    outline: none;
    background-color: transparent;
    transition: border-color 0.3s;
}

.ui-input:focus {
    border-color: #ff6363;
}

.ui-input-underline {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
    background-color: #ff6363;
    transform: scaleX(0);
    transition: transform 0.3s;
}

.ui-input:focus + .ui-input-underline {
    transform: scaleX(1);
}

.ui-input-highlight {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 0;
    background-color: rgba(255, 99, 99, 0.1);
    transition: width 0.3s;
}

.ui-input:focus ~ .ui-input-highlight {
    width: 100%;
}

.ui-input-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #999;
    transition: color 0.3s;
}

.ui-input:focus ~ .ui-input-icon {
    color: #ff6363;
}

.ui-input-icon svg {
    width: 14px;
    height: 14px;
}

.radio-tile-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.radio-tile-group .input-container {
    position: relative;
    height: 40px;
    width: 180px;
    margin: auto;
}

.radio-tile-group .input-container .radio-button {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    cursor: pointer;
}

.radio-tile-group .input-container .radio-tile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border: 2px solid #9b9b9b;
    border-radius: 5px;
    padding: 1rem;
    transition: transform 300ms ease;
}

.radio-tile-group .input-container .icon svg {
    border: 2px solid #9b9b9b;
    fill: #079ad9;
    width: 2rem;
    height: 2rem;
}

.radio-tile-group .input-container .radio-tile-label {
    text-align: center;
    font-size: 0.6rem;
    font-weight: 600;
    letter-spacing: 1px;
    color: #5e5e5e;
}

.radio-tile-group .input-container .radio-button:checked + .radio-tile {
    background-color: #fcb8b8;
    border: 2px solid #fcb8b8;
    color: #5e5e5e;
    transform: scale(1.1, 1.1);
}

.radio-tile-group .input-container .radio-button:checked + .radio-tile .icon svg {
    fill: white;
    background-color: #079ad9;
}

.radio-tile-group .input-container .radio-button:checked + .radio-tile .radio-tile-label {
    color: #5e5e5e;
    background-color: #fcb8b8;
}

.ck.ck-editor__editable_inline {
    min-height: 600px;
}
.editor-container {
    border: 1px solid #dee2e6;
    margin-top: 16px;
}

/*.tox {*/
/*    z-index: 9998;*/
/*}*/

/*.tox-dialog {*/
/*    position: relative;*/
/*    z-index: 9999;*/
/*}*/
